import { IPostTypes } from './../../types/IPostTypes';
import { useQuery, UseQueryOptions } from 'react-query';
import { getPostTypeCollection } from '../getters/getPostTypeCollection';

export function usePostTypeCollection(
  postType: IPostTypes,
  params?: any,
  options?: UseQueryOptions,
) {
  return useQuery<any>(
    ...getPostTypeCollection(postType, params),
    options as any,
  );
}
