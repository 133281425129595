import { IPostTypes } from '../../types/IPostTypes';
import { GET_POST_TYPE_COLLECTION } from '../../../CONSTANTS';
import { QueryTuple } from '../types';
import PostTypeCollectionApi from '../../wp/PostTypeCollectionApi';

export const getPostTypeCollectionTuple = (params?: any) => [
  GET_POST_TYPE_COLLECTION,
  { ...params },
];

const getPostTypeCollection = (
  postType: IPostTypes,
  params?: any,
): QueryTuple => {
  return [
    getPostTypeCollectionTuple({ postType, ...params }),
    async ({ signal }) => {
      const { data, headers } = await new PostTypeCollectionApi().getAll(
        postType,
        params,
        signal,
      );
      return {
        data,
        headers,
      };
    },
  ];
};

export { getPostTypeCollection };
